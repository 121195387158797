import React from 'react';
import { CssBaseline, Typography } from '@material-ui/core';

export default function Privacy() {
  return (
    <React.Fragment>
      <CssBaseline />
       <div style={{ padding: 20, margin: 20, border: 1, borderColor: '#00695c', borderStyle: 'double' }}>
        <Typography style={{ 'textAlign': 'center', padding: 20 }} variant="h4">Privacy Policy</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', margin: 10}}/>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>GammApp Team Studios (“GammApp Team”) has developed this Privacy Policy to help you understand how GammApp Team collects, uses and discloses information GammApp Team may collect from you and/or your device (computer, phone or other mobile device), or that you may provide to GammApp Team, while using GammApp Team’s mobile applications and any of GammApp Team’s websites located at https://gammapp.net. Collectively, the Apps and Websites are referred to as the “GammApp Team Services.”</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>For specific information on how GammApp Team collects and uses information belonging to children under the age of 13 in accordance with the Children’s Online Privacy Protection Act (“COPPA”), please refer to Section 10 of this Privacy Policy.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>By using the GammApp Team Services, you agree to how GammApp Team collects, uses and discloses information that is collected from you. If you are under 18 years old, you acknowledge that your legal guardian has consented to this Privacy Policy. This Privacy Policy is an integral part of the Terms of Use If you, or your legal guardian do not accept or understand this Privacy Policy, please do not use the GammApp Team Services.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>If you have questions or complaints regarding GammApp Team’s Privacy Policy or practices, please contact GammApp Team at https://www.facebook.com/GammApp.Ltd</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >1. DEFINITIONS</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>The following defined terms shall have the meanings set forth below:</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>“Financial Information” means information relating to payments and billing (e.g., name, account information, billing address) provided by you. All Financial Information is collected for billing and payment purposes only and will be protected and administered by GammApp Team and/or our authorized third party partners.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>“Non-Personal Information” means information that, by itself, does not identify a specific individual or entity (e.g., demographic information, usage data or downloads).</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>“Personal Information” means specific identifiable information about an individual or entity which may be used to identify, contact or locate that person or entity (e.g., name, mailing address, Device ID (defined below), email address or phone number).</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >2. WHAT INFORMATION IS COLLECTED?</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Information You Provide. GammApp Team may receive and store any Personal Information, Financial Information and/or Non-Personal Information that you provide when you interact with GammApp Team. Some features and/or functionality of the GammApp Team Services may be unavailable if you choose not to share certain information. GammApp Team may use information you provide to communicate with you and to improve the GammApp Team Services. Here are some examples:</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Email Communications. GammApp Team may use your Personal Information to send you newsletters and emails related to promotions and/or events. You can opt out of receiving promotional emails by following the instructions in the relevant correspondence. Once GammApp Team has processed your opt-out request, GammApp Team will not send you further promotional emails unless you later opt back in. Whether you opt out of receiving promotional emails or not, GammApp Team reserves the right to email you with respect to important information, notices or changes relating to the GammApp Team Services.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Contact Us. If you choose to use the “Contact Us” form you will be asked to provide Personal Information, request type of inquiry, and describe the purpose for which you are contacting GammApp Team. If GammApp Team is aware that you are under the age of 13, GammApp Team will delete your email address once your request has been fulfilled.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Geolocation Data. If your location services are enabled, GammApp Team may collect your geolocation information from GammApp Team’s mobile application, but does not ask you for, access, or track any location based information at any time while downloading or using other GammApp Team Services.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Push Notifications. On occasion, GammApp Team will send you push notifications when using GammApp Team’s mobile applications regarding alerts to new high scores, as well as other account information that may be important to you. If you no longer wish to receive these types of communications, you may opt-out from receiving them by turning them off at the device level through the settings of the device or by contacting GammApp Team at https://www.facebook.com/GammApp.Ltd</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Other Information. You may provide other Personal and Non-Personal information whenever you interact with the GammApp Team Services, including, without limitation, when you search the GammApp Team Services, participate in a contest, survey or questionnaire, place an order, or communicate with GammApp Team by phone or email.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>With any of the above actions, you may supply GammApp Team with information such as your name, address, phone number, alternate contact information, information about your use of the GammApp Team Services, personal descriptions and product descriptions.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Information Collected Automatically or Through Technology. GammApp Team may receive and store certain Personal Information and Non-Personal Information whenever you interact with GammApp Team. While you are able to visit the GammApp Team Services and remain anonymous, GammApp Team and/or GammApp Team’s third-party service providers may still collect Non-Personal Information through technological means. Such information may be gathered by the following methods:</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Tracking. Technologies such as cookies, beacons, tags and scripts are used by us and our partners, affiliates, or analytics or service providers. These technologies are used in analyzing trends, administering the site, tracking user’s movements around the site and to gather demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual as well as aggregated basis.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Device Information. When you download and use the GammApp Team Services, GammApp Team automatically collects information on the type of device you use, operating system, application version, and the persistent device identifier (“Device ID”). You consent to GammApp Team collecting this information.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Metadata. Metadata is data collected and used to facilitate the GammApp Team Services, monitor system usage, prevent fraud, and for other services. Some examples of metadata that GammApp Team may collect and analyze include, without limitation, the Internet protocol (IP) address used to connect your computer to the Internet, computer and connection information (such as browser type, version, and time zone setting, browser plug-in types and versions, operating system, and platform), the full Uniform Resource Locator (URL) click sequence to, through, and from the GammApp Team Services, including date and time, cookie number and any products, services or features you use or interact with arising out of or in connection with your use of the GammApp Team Services.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Log files. As true of most websites, GammApp Team gathers certain information automatically and stores it in log files. This information may include internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Tools. GammApp Team may use software tools to measure and collect information about your use of the GammApp Team Services, including, without limitation, page response times, upload and file transfer errors, interaction information (such as scrolling, clicks, and mouse- overs), features use, purchase tracking and order fulfillment and methods. Sometimes, you will have a choice whether or not to provide information, but if you fail to provide certain information it could impact some features and/or functionalities of the GammApp Team Services.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Information from Other Sources. GammApp Team may receive information about you from GammApp Team’s third-party service providers, or other sources such as purchase or redemption information,GammApp Team Services usage, payment information, reporting information, page-view information, technical, fulfillment, advertising, search information and credit history information.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Mobile Analytics. GammApp Team uses mobile analytics software to better understand the functionality of its Mobile Software on your phone. This software may record information such as how often you use the application, the events that occur within the application, aggregated usage, performance data, and where the application was downloaded from. GammApp Team does not link the information stored within the analytics software to any personal information you submit within the application.</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >3. HOW INFORMATION IS USED, SHARED AND DISCLOSED</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>GammApp Team does not sell, and generally does not disclose, your Personal Information to third-parties without your consent. Except as set forth in Section 10 of this Privacy Policy, parents have the right to consent to collection and use of personal information from their child without also consenting to its disclosure to third-parties, as GammApp Team does not share information that may be collected from your child. GammApp Team uses Personal, Non-Personal and Financial Information, among other things, to monitor system performance, analyze internal business systems, improve the GammApp Team Services, fulfill requests for additional products and services, contact you about promotional and marketing activities, provide customer support, and conduct research. The use and protection of information is an important component of GammApp Team’s business. Examples of how GammApp Team shares and discloses your information is as follows:</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Legal. GammApp Team may disclose information you provide to respond to subpoenas, court orders, requests from authorities, and other legal process, and to establish/exercise GammApp Team’s legal rights. GammApp Team may also disclose your information to defend against legal claims when GammApp Team believes that disclosure is necessary to protect GammApp Team’s rights, protect your safety or the safety of others, investigate fraud, or respond to a government request. GammApp Team may share Personal Information in order to investigate, prevent or take action regarding illegal activities, suspected fraud, and situations involving potential threats to the physical safety of any person, violations of GammApp Team’s Terms of Use, or as otherwise required by law.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Business Transfers. As GammApp Team continues to develop its business, GammApp Team may reorganize as a business entity, acquire entities, subsidiaries, or business units, or enter into joint ventures with third-parties. In any of these types of transactions, any information you provide may be one of the transferred business assets (subject to any pre-existing privacy notice unless you consent otherwise). In the event that all or substantially all of GammApp Team’s assets are acquired or GammApp Team merges with a third-party, your information will likely be one of the transferred assets. You understand and agree that GammApp Team reserves the right to transfer any information collected from you in the case of any such transfer. In such event, GammApp Team will use reasonable efforts to notify you before Personal Information about you is transferred and becomes subject to a different privacy policy.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Affiliated Businesses We Do Not Control. GammApp Team may use third-party companies and individuals to perform functions on GammApp Team’s behalf. Examples include fulfilling orders, processing payments, hosting, data storage, sending postal mail and email, removing repetitive information from customer lists, analyzing data, providing marketing assistance, providing search results and links (including paid listings and links), processing credit card payments, and providing customer service. GammApp Team will provide such entities with access to certain information needed to perform their functions, but will take measures to ensure that they may not use it for other purposes. GammApp Team shares information only as described above and with third-parties that are either subject to this Privacy Policy or to a privacy policy that is at least as protective as this Privacy Policy.</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >4. SECURITY</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>The security of your Personal Information is important to GammApp Team. GammApp Team follows generally accepted industry standards to protect the Personal Information submitted to GammApp Team, both during transmission and once it is received. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, GammApp Team cannot guarantee its absolute security. While no security system is completely secure, GammApp Team and its third-party partners and vendors take appropriate security measures to protect against unauthorized access or disclosure of the information collected by GammApp Team. For GammApp Team, this includes, but is not limited to, ensuring that all Personal and Financial Information is disclosed only on a “need to know” basis and ensuring that all Personal Information collected by GammApp Team is done so via secured connections and protected and encrypted (using secure socket layer technology,”SSL”) by industrial grade security software to guard against unauthorized access.</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >5. ADVERTISING</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>GammApp Team partners with third parties to display advertising throughout the Services or to manage its advertising on other sites. Our third party partner may use cookies or similar technologies in order to provide you with advertising based upon your browsing activities and interests if you are older than 13, and contextual advertising if you are younger than 13. Please note you will continue to receive generic ads.</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >6. SOCIAL MEDIA FEATURES AND TOOLS</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>The GammApp Team Services may include integrated social media features and tools, such as the Facebook “Like” button, the YouTube “Share This” button, and interactive mini-programs that run in the GammApp Team Services. These Features may collect information on your IP address, which page you are visiting on GammApp Team’s site, and may set a cookie to enable the Feature to function properly. Social media features and tools are either hosted by a third-party or hosted directly on the GammApp Team Services. Your interactions with these integrated social media features and tools are governed by the privacy policy of the company providing it.</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >7. GOOGLE+ OR OTHER OPENID PROVIDER</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>You may be able to log in to some of GammApp Team’s mobile applications using sign-in services such as Google+ or an Open ID provider. These services will authenticate your identity and provide you the option to share certain personal information with GammApp Team such as your name and email address to pre-populate GammApp Team’s sign up form. Services like Google+ give you the option to post information about your activities on the GammApp Team website to your profile page to share with others within your network.</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >8. LINKS TO OTHER SITES</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>The GammApp Team Services contain links to other sites that are not owned or controlled by GammApp Team. Please be aware that GammApp Team is not responsible for the privacy practices of such other sites. GammApp Team encourages you to be aware when you leave GammApp Team’s site and to read the privacy statements of each and every website that collects personally identifiable information. This Privacy Policy applies only to information collected by the GammApp Team Services.</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >9. ACCESS/DATA RETENTION</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>If you or your child’s information changes, or if you no longer desire the GammApp Team Services, you may make corrections and updates, delete inaccuracies, or request deletion or further contact with you or your child, by updating your account information or by contacting GammApp Team at https://www.facebook.com/GammApp.Ltd. GammApp Team will respond to your access request within a reasonable timeframe.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>GammApp Team will retain you and your child’s information for as long as you or your child’s account is active, or as needed to provide services. If you wish to cancel your child’s account or request that GammApp Team no longer use the information to provide services, contact GammApp Team at the email address listed above. GammApp Team will retain and use the information as necessary to comply with our legal obligations, resolve disputes and enforce our agreements.</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >10. SPECIAL NOTICE REGARDING CHILDREN</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Information Collected. GammApp Team does not knowingly collect personal information from children under the age of 13 without parental consent, except as permitted under COPPA. For example, GammApp Team, and third parties acting on its behalf, collect IP addresses and other persistent identifiers from children under the age of 13 to support internal operations for the Services, including but not limited to, analytics and to serve contextual advertising to children.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>If it comes to GammApp Team’s attention that Personal Information has been collected from a person under the age of 13, GammApp Team will delete this information as quickly as possible. If you believe that GammApp Team may have Personal Information from or about a child under the age of 13, please contact GammApp Team at https://www.facebook.com/GammApp.Ltd.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Parental Access. If you wish to update, correct, or delete the Personal Information that GammApp Team has for your child, you may do so by contacting GammApp Team at https://www.facebook.com/GammApp.Ltd</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >11. CONDITIONS OF USE/CHANGES TO THIS PRIVACY POLICY</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>GammApp Team may update this Privacy Policy to reflect changes to information practices. If GammApp Team makes any material changes, GammApp Team will notify you by email if you have provided an email address to GammApp Team or by means of a notice on GammApp Team’s Services, prior to the change becoming effective. GammApp Team encourages you to periodically review this page for the latest information on its privacy practices. If GammApp Team makes material changes to how Personal Information collected from children under age 13 is used, GammApp Team will notify parents by email in order to obtain verifiable parental consent or, if GammApp Team does not have such emails to contact parents directly, by reasonable means on GammApp Team’s website for the new uses of the child’s Personal Information.</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >12. CONTACT US</Typography>
        <Typography variant='body2' style={{textAlign: 'center'}}>If you have questions or comments regarding how GammApp Team protects your information or this Privacy Policy, please contact us at: https://www.facebook.com/GammApp.Ltd</Typography>
        <div style={{height: 1, margin: 50}}/>
        <Typography variant='body2' style={{textAlign: 'center'}}>Copyright &#169; 2021 gammapp.net</Typography>
      </div>
    </React.Fragment>
  );
}
