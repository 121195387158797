import React from 'react';
import { CssBaseline, Typography } from '@material-ui/core';

export default function Terms() {
  return (
    <React.Fragment>
      <CssBaseline />
       <div style={{ padding: 20, margin: 20, border: 1, borderColor: '#00695c', borderStyle: 'double' }}>
        <Typography style={{ 'textAlign': 'center', padding: 20 }} variant="h4">Terms of use</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', margin: 10}}/>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>These terms and conditions outline the rules and regulations for the use of GammApp’s Website.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>By accessing this website we assume you accept these terms and conditions in full. Do not continue to use GammApp’s website if you do not accept all of the terms and conditions stated on this page.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website and accepting the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services/products, in accordance with and subject to, prevailing law of Vietnam. Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >Cookies</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>We employ the use of cookies. By using GammApp’s website you consent to the use of cookies in accordance with GammApp’s privacy policy.Most of the modern day interactive web sites use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those people visiting. Some of our affiliate / advertising partners may also use cookies. License Unless otherwise stated, GammApp and/or it’s licensors own the intellectual property rights for all material on GammApp. All intellectual property rights are reserved. You may view and/or print pages from https://gammapp.net for your own personal use subject to restrictions set in these terms and conditions.</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >You must not:</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Republish material from https://gammapp.net</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Sell, rent or sub-license material from https://gammapp.net</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Reproduce, duplicate or copy material from https://gammapp.net</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Redistribute content from GammApp (unless content is specifically made for redistribution).</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>The following organizations may link to our Web site without prior written approval:</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>- Government agencies;</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>- Search engines;</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>- News organizations;</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Online directory distributors when they list us in the directory may link to our Web site in the same manner as they hyperlink to the Web sites of other listed businesses; and Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>These organizations may link to our home page, to publications or to other Web site information so long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>We may consider and approve in our sole discretion other link requests from the following types of organizations: commonly-known consumer and/or business information sources such as Chambers of Commerce, American Automobile Association, AARP and Consumers Union; dot.com community sites; associations or other groups representing charities, including charity giving sites, online directory distributors; internet portals; accounting, law and consulting firms whose primary clients are businesses; and educational institutions and trade associations.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>We will approve link requests from these organizations if we determine that: (a) the link would not reflect unfavorably on us or our accredited businesses (for example, trade associations or other organizations representing inherently suspect types of business, such as work-at-home opportunities, shall not be allowed to link); (b)the organization does not have an unsatisfactory record with us; (c) the benefit to us from the visibility associated with the hyperlink outweighs the absence of ; and (d) where the link is in the context of general resource information or is otherwise consistent with editorial content in a newsletter or similar product furthering the mission of the organization.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>These organizations may link to our home page, to publications or to other Web site information so long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and it products or services; and (c) fits within the context of the linking party’s site.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>If you are among the organizations listed in paragraph 2 above and are interested in linking to our website, you must notify us by sending an message to https://www.facebook.com/GammApp.Ltd. Please include your name, your organization name, contact information (such as a phone number and/or e-mail address) as well as the URL of your site, a list of any URLs from which you intend to link to our Web site, and a list of the URL(s) on our site to which you would like to link. Allow 2-3 weeks for a response.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Approved organizations may hyperlink to our Web site as follows: By use of our corporate name; or By use of the uniform resource locator (Web address) being linked to; or By use of any other description of our Web site or material being linked to that makes sense within the context and format of content on the linking party’s site.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>No use of GammApp’s logo or other artwork will be allowed for linking absent a trademark license agreement.</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >Iframes</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Without prior approval and express written permission, you may not create frames around our Web pages or use other techniques that alter in any way the visual presentation or appearance of our Web site. Reservation of Rights</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>We reserve the right at any time and in its sole discretion to request that you remove all links or any particular link to our Web site. You agree to immediately remove all links to our Web site upon such request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuing to link to our Web site, you agree to be bound to and abide by these linking terms and conditions.</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >Removal of links from our website</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>If you find any link on our Web site or any linked web site objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date.</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >Content Liability</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any page on your Web site or within any context containing content or materials that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</Typography>
        <div style={{height: 1, backgroundColor: '#00695c', marginLeft: 50, marginRight: 50, marginTop: 10}}/>
        <Typography variant='body2' style={{padding: 10, textAlign: 'center'}} >Disclaimer</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>- limit or exclude our or your liability for death or personal injury resulting from negligence;</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>- limit or exclude our or your liability for fraud or fraudulent misrepresentation;</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>- limit any of our or your liabilities in any way that is not permitted under applicable law; or exclude any of our or your liabilities that may not be excluded under applicable law.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>To the extent that the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</Typography>
        <Typography variant='body2' style={{textAlign: 'justify', textJustify: 'inter-word'}}>Facebook: https://www.facebook.com/GammApp.Ltd</Typography>
        <div style={{height: 1, margin: 50}}/>
        <Typography variant='body2' style={{textAlign: 'center'}}>Copyright &#169; 2021 gammapp.net</Typography>
      </div>
    </React.Fragment>
  );
}